import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { PolicyWrapper } from './style';
import { LinkText, Text } from '../Typography/Text';
import useResize from '@/hooks/useResize';

// Function to set a cookie with an expiration time
const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// Function to get a cookie by name
const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Function to delete a cookie
// const deleteCookie = (name: string) => {
//   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
// };

const CookiePolicy = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet } = useResize();

  // const devMode = process.env.NODE_ENV === 'development';

  // 檢查是否有cookie的同意
  const cookiePolicy = getCookie('cookiePolicy');
  const cookiePolicyRef = useRef<HTMLDivElement>(null);

  // 設定cookie的同意與否，並設定半年後過期
  const setCookiePolicy = (status: boolean) => {
    setCookie('cookiePolicy', status ? 'true' : 'false', 180); // 180 days ~ 6 months
    if (!cookiePolicyRef) return;
    cookiePolicyRef.current!.classList.remove('active');
    // 給cookiePolicyRef.current在1秒後加上hidden class
    setTimeout(() => {
      if (cookiePolicyRef.current) {
        cookiePolicyRef.current.classList.add('hidden');
      }
    }, 1000);
  };

  // 確認使用者是否有點擊同意，若有則隱藏cookie policy, 若無則顯示
  useEffect(() => {
    if (cookiePolicyRef.current && cookiePolicy === 'true') {
      cookiePolicyRef.current.style.display = 'none';
    } else {
      // 給cookiePolicyRef.current在loading完後的2秒後加上active class
      setTimeout(() => {
        if (cookiePolicyRef.current) {
          cookiePolicyRef.current.classList.add('active');
        }
      }, 1500);
    }
  }, [cookiePolicy]);

  // 測試用按紐取消cookie同意
  // const clearCookie = () => {
  //   deleteCookie('cookiePolicy');
  // };

  return (
    <>
      <PolicyWrapper ref={cookiePolicyRef}>
        <Flex justifyContent={'space-between'} flexDirection={isTablet ? 'column' : 'row'} gridGap={isTablet ? '20px' : '0'}>
          <Flex alignItems={'center'} gridGap={20}>
            <Text>This website uses cookies to ensure you get the best experience on our website.</Text>
            <a target="_blank" rel="noreferrer" href="/files/PICSforDeal-makingPortal.pdf">
              <Text>
                <LinkText>Cookies Policy</LinkText>
              </Text>
            </a>
          </Flex>
          <Flex gridGap={10} justifyContent={isTablet ? 'center' : 'flex-start'}>
            <Button
              onClick={() => {
                setCookiePolicy(true);
              }}
              size={'lg'}
              variant={'primary'}
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                setCookiePolicy(false);
              }}
              size={'lg'}
              variant={'blackoutline'}
            >
              Decline
            </Button>
          </Flex>
        </Flex>
      </PolicyWrapper>
      {/* {devMode && <button onClick={clearCookie}>Clear Cookie</button>} */}
    </>
  );
};

export default CookiePolicy;
