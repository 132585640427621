import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';

// 手動指定 CDN 上的 worker 檔案路徑
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js`;

interface PdfReaderProps {
  pdfUrl: string;
  setIsLoading: ((isLoading: boolean) => void);
}

const PdfReader: React.FC<PdfReaderProps> = ({ pdfUrl, setIsLoading }) => {
  const viewerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadPdf = async () => {
      if (!viewerRef.current) return;

      // 清空之前的畫布
      viewerRef.current.innerHTML = '';

      // 加載 PDF 文件
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;

      // 迴圈遍歷每一頁
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        console.log(pageNum);
        const page = await pdf.getPage(pageNum);

        // 根據 modal 寬度自動調整 scale
        const containerWidth = viewerRef.current.getBoundingClientRect().width;
        const viewport = page.getViewport({ scale: containerWidth / page.getViewport({ scale: 1 }).width });

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return;

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        viewerRef.current.appendChild(canvas);

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        await page.render(renderContext).promise;
        setIsLoading(false);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  return (
    <div className="pdf-modal">
      <div className="pdf-content" ref={viewerRef}></div>
    </div>
  );
};

export default PdfReader;
